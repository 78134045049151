<template>
  <div class="local-filter">
    <div class="local-filter__fields fields">
      <div
        v-for="field in currentFields"
        :key="field.id"
        class="fields__filter filter"
      >
        <p class="filter__title title">{{ field.title }}</p>
        <sm-input
          v-if="field.type === FIELD_TYPES['text']"
          v-model="filterValue[field.id]"
          :disabled="fieldsIsDisabled"
        ></sm-input>
        <sm-numeric-input
          v-if="field.type === FIELD_TYPES['number']"
          v-model="filterValue[field.id]"
          :disabled="fieldsIsDisabled"
          class="filter__number number"
        ></sm-numeric-input>
        <sm-select
          v-if="field.type === FIELD_TYPES['select']"
          v-model="filterValue[field.id]"
          :options="field.items"
          :search="true"
          :disabled="fieldsIsDisabled"
        ></sm-select>
        <sm-datepicker
          v-if="field.type === FIELD_TYPES['date']"
          v-model="filterValue[field.id]"
          :disabled="fieldsIsDisabled"
        ></sm-datepicker
      ></div>
    </div>
    <div class="local-filter__controls controls">
      <sm-button
        :isLoading="loading"
        :disabled="applyBtnIsDisabled"
        @click="applyFilterValue()"
        class="controls__button button button--apply"
        >Применить фильтр</sm-button
      >
      <sm-button
        :disabled="resetBtnIsDisabled"
        @click="initFilterValue()"
        class="controls__button button button--reset"
        neutrall
        outline
        >Сбросить</sm-button
      >
      <sm-button
        v-if="showToggleButton"
        @click="onToggleExpandFilter()"
        class="controls__button button button--toggle"
        neutrall
        outline
        >{{
          !filterExpanded ? 'Раскрыть фильтры' : 'Скрыть фильтры'
        }}</sm-button
      >
    </div>
  </div>
</template>

<script>
import SmInput from '@/components/common/forms/SmInput.vue';
import SmNumericInput from '@/components/common/forms/SmNumericInput.vue'
import SmSelect from '@/components/common/forms/SmSelect.vue';
import smDatepicker from '@/components/common/forms/SmDatepicker.vue';
import SmButton from '@/components/common/buttons/SmButton.vue';

export default {
  name: 'LocalFilter',

  components: {
    SmInput,
    SmNumericInput,
    SmSelect,
    smDatepicker,
    SmButton,
  },

  props: {
    fields: { type: Array, required: true },
    loading: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },

  data() {
    return {
      FIELD_TYPES: {
        text: 'text',
        select: 'select',
        date: 'date',
        number: 'number'
      },
      filterValue: {},
      lastAppliedFilterValue: {},
      filterExpanded: false,
      windowWidth: 0,
    };
  },

  computed: {
    fieldsIsDisabled() {
      const disabled = this.disabled;
      const loading = this.loading;

      if (disabled || loading) return true;
      return false;
    },

    applyBtnIsDisabled() {
      const disabled = this.disabled;
      const filterValue = this.filterValue;
      const lastAppliedFilterValue = this.lastAppliedFilterValue;

      if (disabled) return true;
      if (!this.lodash.isEqual(filterValue, lastAppliedFilterValue)) {
        return false;
      }
      return true;
    },

    resetBtnIsDisabled() {
      const fieldsIsDisabled = this.fieldsIsDisabled;
      const filterValue = this.filterValue;
      const lastAppliedFilterValue = this.lastAppliedFilterValue;

      if (fieldsIsDisabled) return true;

      for (let filterId in filterValue) {
        const value = filterValue[filterId];
        if (value) return false;
      }

      for (let filterId in lastAppliedFilterValue) {
        const value = lastAppliedFilterValue[filterId];
        if (value) return false;
      }

      return true;
    },

    showToggleButton() {
      const windowWidth = this.windowWidth;
      const fields = this.fields;

      if (windowWidth > 750 && fields.length > 2) return true;
      if (fields.length > 3) return true;

      return false;
    },

    currentFields() {
      const fields = this.fields;
      const filterExpanded = this.filterExpanded;
      const windowWidth = this.windowWidth;
      const expandedFilterCount = windowWidth > 750 ? 3 : 2;

      if (!filterExpanded) {
        return fields.slice(0, expandedFilterCount);
      }

      return fields;
    },
  },

  whatch: {
    fields: {
      handler() {
        this.initFilterValue();
      },
    },
  },

  created() {
    this.initFilterValue();
  },

  mounted() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },

  methods: {
    initFilterValue() {
      this.filterValue = {};

      for (let i = 0; i < this.fields.length; i++) {
        const currentField = this.fields[i];
        this.$set(this.filterValue, currentField.id, '');
      }

      this.applyFilterValue();
    },

    applyFilterValue() {
      this.lastAppliedFilterValue = this.lodash.cloneDeep(this.filterValue);
      this.$emit('change', this.lastAppliedFilterValue);
    },

    onToggleExpandFilter() {
      this.filterExpanded = !this.filterExpanded;
    },

    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
};
</script>

<style lang="scss">
.local-filter {
  margin-bottom: 30px;
}

.local-filter__fields {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-bottom: 20px;

  @media (max-width: 750px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.local-filter .title {
  color: var(--gray);
}

.local-filter .number .numeric-input__buttons {
  top: 6px
}

.local-filter__controls {
  display: flex;
  gap: 15px;
}

.local-filter .controls {
  & .button {
    width: 180px;

    &--toggle {
      margin-left: auto;
    }
  }
}
</style>
