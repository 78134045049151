<template>
  <sm-editable-item
    v-model="form"
    :controllerName="controllerName"
    :isLoading="isLoadingPage"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="fields"
    :disabledSaveButton="isEqual"
    @save="onSave('Sites')"
    @cancel="onCancel('Sites')"
    class="site-edit"
  >
    <template #tab-1>
      <div class="site-edit__contracts-table-tab contracts-table-tab">
        <local-filter
          :fields="filterFields"
          :disabled="!contracts.data || !contracts.data.length"
          :loading="contracts.isLoading"
          @change="changeFilter($event)"
        >
        </local-filter>
        <sm-datatable
          :caption="table.caption"
          :headers="table.headers"
          :items="table.items"
          :sort="true"
          :sortedType="sort.type"
          :activeOrder="sort.order"
          @sort-by="changeSort($event)"
          key="contracts-table"
          class="contracts-table"
        >
          <template #show-button="{ row, index }">
            <sm-button
              :disabled="!row.services.length"
              @click="onToggleExpandButton(row, index)"
            >
              {{
      expandedRow && expandedRow.number === row.number
        ? 'Скрыть услуги'
        : 'Показать услуги'
    }}
            </sm-button>
          </template>
          <template v-slot:[expandedSlotName]>
            <tr>
              <td colspan="9">
                <table
                  v-if="expandedRow && expandedRow.services.length"
                  class="contracts-table__services-table services-table"
                >
                  <tr class="services-table__row row">
                    <th class="row__data data data--service">Наименование</th>
                    <th class="row__data data data--amount">Цена</th>
                    <th class="row__data data data--quantity">Количество</th>
                    <th class="row__data data data--sum">Сумма</th>
                  </tr>
                  <tr
                    v-for="(service, index) in expandedRow.services"
                    :key="index"
                    class="services-table__row row"
                  >
                    <td class="row__data data data--service">
                      {{ service.service }}
                    </td>
                    <td class="row__data data data--amount">
                      {{ service.amount }} ₽
                    </td>
                    <td class="row__data data data--quantity">
                      {{ service.quantity }}
                    </td>
                    <td class="row__data data data--sum">
                      {{ service.sum }} ₽
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </template>
          <template #file-button="{ row }">
            <div class="contracts-table-tab__file-buttons file-buttons">
              <sm-button
                @click="onDownloadContract(row.number)"
                class="file-buttons__button"
              >
                Скачать договор
              </sm-button>
              <sm-button
                @click="onToggleShowUploadContractModal(row.number)"
                class="file-buttons__button"
              >Загрузить договор</sm-button>
            </div>
          </template>
        </sm-datatable>
        <sm-modal
          :show="uploadContractModal.open"
          :modalTitle="`Загрузка файла договора (${uploadContractModal.number})`"
          @close="onToggleShowUploadContractModal()"
          class="contracts-table-tab__upload-file-modal upload-file-modal"
        >
          <template #body>
            <sm-fileinput
              @changeFile="(file) => onChangeFile(file)"
              @removeFile="onRemoveFile()"
              class="upload-file-modal__input"
            ></sm-fileinput>
            <div class="upload-file-modal__controls controls">
              <sm-button
                :loading="uploadContractModal.loading"
                :disabled="!uploadContractModal.file.guid"
                @click="onUploadFile()"
                class="controls__control control"
              >
                Загрузить
              </sm-button>
              <sm-button
                @click="onToggleShowUploadContractModal()"
                class="controls__control control"
              >
                Отменить
              </sm-button>
            </div>
          </template>
        </sm-modal>
      </div>
    </template>
  </sm-editable-item>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import editableListItemEdit from '@/mixins/editableListItemEdit.js';

import SmEditableItem from '@/components/views/SmEditableItem.vue';
import LocalFilter from '@/components/client-sites/LocalFilter.vue';
import SmDatatable from '@/components/common/SmDatatable.vue';
import smButton from '@/components/common/buttons/SmButton.vue';
import SmModal from '@/components/common/modals/SmModal.vue';
import SmFileinput from '@/components/common/forms/SmFileinput.vue';

export default {
  name: 'SiteEdit',

  components: {
    SmEditableItem,
    LocalFilter,
    SmDatatable,
    smButton,
    SmModal,
    SmFileinput,
  },

  mixins: [editableListItemEdit],

  data() {
    return {
      isLoadingPage: false,
      controllerName: 'Sites',
      pageHeader: 'Редактирование cайта клиентов',
      breadcrumbs: [
        {
          text: 'Клиенты',
          route: { name: 'CustomersMain' },
        },
        {
          text: 'Сайты клиентов',
          route: { name: 'Sites' },
        },
        {
          text: 'Редактирование cайта клиентов',
        },
      ],
      form: {},
      filterValue: null,
      sort: {
        order: 'date',
        type: 'desc',
      },
      expandedRow: null,
      uploadContractModal: {
        open: false,
        loading: false,
        number: null,
        file: {
          name: '',
          guid: null,
        },
      },
    };
  },

  computed: {
    ...mapState({
      item: (state) => state.editableList.item,
      customers: (state) => state.common.customers,
      contracts: (state) => state.customers.contracts,
    }),

    fields() {
      return [
        {
          tab: 'Основные',
          form: [
            {
              type: 'text',
              key: 'name',
              label: 'Название',
            },
            {
              type: 'select',
              key: 'customerId',
              label: 'Клиент',
              list: this.customers.data,
            },
            {
              type: 'text',
              key: 'url',
              label: 'Адрес сайта',
            },
            {
              type: 'text',
              key: 'created',
              label: 'Дата создания',
              disabled: true,
            },
            {
              type: 'text',
              key: 'demoCode',
              label: 'Демо-код',
              disabled: true,
            },
            {
              type: 'checkbox',
              key: 'dontControlGisExport',
              label: 'Не контролировать выгрузку в ГИС ЖКХ',
            },
          ],
        },
        {
          tab: 'Договоры',
        },
      ];
    },

    augmentedContracts() {
      const contracts = this.contracts.data || [];

      return contracts.map((contract) => {
        return {
          ...contract,
          sum: `${contract.services.reduce(
            (sum, service) => sum + +service.sum,
            0
          )} ₽`,
        };
      });
    },

    selectFilterElements() {
      const contracts = this.augmentedContracts;
      const filterElements = {
        type: new Set(),
        periodic: new Set(),
        status: new Set(),
      };

      for (let i = 0; i < contracts.length; i++) {
        const currentContract = contracts[i];

        if (currentContract.type) {
          filterElements.type.add(currentContract.type);
        }
        if (currentContract.periodic) {
          filterElements.periodic.add(currentContract.periodic);
        }
        if (currentContract.status) {
          filterElements.status.add(currentContract.status);
        }
      }

      filterElements.type = [...filterElements.type];
      filterElements.periodic = [...filterElements.periodic];
      filterElements.status = [...filterElements.status];

      return filterElements;
    },

    selectFilterItems() {
      const selectFilterElements = this.selectFilterElements;
      const filterItems = {
        type: selectFilterElements.type.map((type) => {
          return { name: type, value: type };
        }),
        periodic: selectFilterElements.periodic.map((periodic) => {
          return { name: periodic, value: periodic };
        }),
        status: selectFilterElements.status.map((status) => {
          return { name: status, value: status };
        }),
      };

      return filterItems;
    },

    filterFields() {
      const selectFilterItems = this.selectFilterItems;

      return [
        { id: 'number', title: 'Номер', type: 'text' },
        {
          id: 'type',
          title: 'Вид',
          type: 'select',
          items: selectFilterItems.type,
        },
        { id: 'date', title: 'Дата заключения', type: 'date' },
        {
          id: 'periodic',
          title: 'Периодичность',
          type: 'select',
          items: selectFilterItems.periodic,
        },
        { id: 'sum', title: 'Сумма', type: 'number' },
        {
          id: 'status',
          title: 'Статус',
          type: 'select',
          items: selectFilterItems.status,
        },
        { id: 'partner', title: 'Профиль', type: 'text' },
      ];
    },

    filteredContracts() {
      const contracts = this.augmentedContracts;
      const filterValue = this.filterValue;
      const checkIncludes = (contract, field) => {
        return contract[field].includes(filterValue[field]);
      };
      const checkNumber = (contract, field) => {
        return parseInt(contract[field]) === filterValue[field];
      };
      let filteredContracts = contracts;

      if (filterValue) {
        filteredContracts = filteredContracts.filter((contract) => {
          if (filterValue.number && !checkIncludes(contract, 'number')) {
            return false;
          }
          if (filterValue.partner && !checkIncludes(contract, 'partner')) {
            return false;
          }
          if (filterValue.periodic && !checkIncludes(contract, 'periodic')) {
            return false;
          }
          if (filterValue.sum && !checkNumber(contract, 'sum')) {
            return false;
          }
          if (filterValue.status && !checkIncludes(contract, 'status')) {
            return false;
          }
          if (filterValue.type && !checkIncludes(contract, 'type')) {
            return false;
          }
          if (filterValue.date && !(contract.date === filterValue.date)) {
            return false;
          }

          return true;
        });
      }

      return filteredContracts;
    },

    displayedContracts() {
      const filteredContracts = this.filteredContracts;
      const sort = this.sort;
      const fieldType = sort.order === 'date' ? 'date' : 'string';

      return this.getSortedList({
        list: filteredContracts,
        fieldType,
        fieldName: sort.order,
        orderType: sort.type,
      });
    },

    table() {
      const displayedContracts = this.displayedContracts;

      return {
        caption: 'Договоры',
        headers: [
          { text: 'Номер', order: 'number', alias: 'number' },
          { text: 'Вид', order: 'type', alias: 'type' },
          { text: 'Дата заключения', order: 'date', alias: 'date' },
          { text: 'Периодичность', order: 'periodic', alias: 'periodic' },
          { text: 'Сумма', order: 'sum', alias: 'sum' },
          { text: 'Статус', order: 'status', alias: 'status' },
          { text: 'Профиль', order: 'partner', alias: 'partner' },
          { text: '', order: '', alias: 'show-button' },
          { text: '', order: '', alias: 'file-button' },
        ],
        items: displayedContracts,
        showCreateButton: false,
        showEditButton: false,
        showDeleteButton: false,
      };
    },

    expandedSlotName() {
      const expandedRowIndex = this.expandedRow?.rowIndex;

      if (expandedRowIndex !== null) {
        return `row-bottom-${expandedRowIndex}`;
      }
      return '';
    },
  },

  watch: {
    'form.customerId': {
      handler(newValue, oldValue) {
        if (newValue === oldValue) return;
        this.getContracts(newValue);
      },
    },
  },

  created() {
    this.isLoadingPage = true;

    const customers = this.getCommonList({ name: 'Customers' });
    const item = this.getItem({ name: this.controllerName, id: this.id }).then(
      () => {
        this.form = this.lodash.cloneDeep(this.item.data);
      }
    );

    Promise.all([customers, item]).finally(() => {
      this.isLoadingPage = false;
    });
  },

  methods: {
    ...mapActions({
      getItem: 'editableList/getItem',
      updateItem: 'editableList/updateItem',
      getCommonList: 'common/getCommonList',
      getContracts: 'customers/getContracts',
      downloadContract: 'customers/downloadContract',
      addFile: 'pendingFiles/addFile',
      removeFiles: 'pendingFiles/removeFiles',
      uploadFile: 'customers/uploadFile',
    }),

    changeFilter(filterValue) {
      this.expandedRow = null;
      this.filterValue = filterValue;
    },

    changeSort({ order, type }) {
      if (!order) return;
      this.expandedRow = null;
      this.$set(this.sort, 'order', order);
      this.$set(this.sort, 'type', type);
    },

    onDownloadContract(contractNumber) {
      this.downloadContract({
        customerId: this.form.customerId,
        contractNumber,
      });
    },

    onToggleShowUploadContractModal(contractNumber = null) {
      if (contractNumber) {
        this.$set(this.uploadContractModal, 'number', contractNumber);
      } else {
        this.onRemoveFile();
      }

      this.$set(
        this.uploadContractModal,
        'open',
        !this.uploadContractModal.open
      );
    },

    onChangeFile(newFile) {
      const { file } = this.uploadContractModal;

      if (file.guid) {
        this.removeFiles({ filesToRemove: [file.guid] });
      }

      if (newFile instanceof FileList) {
        this.$set(this.uploadContractModal, 'loading', true);

        const fd = new FormData();

        fd.append('file', newFile[0], newFile[0].name);

        this.addFile(fd).then((newFileId) => {
          this.$set(this.uploadContractModal.file, 'name', newFile[0].name);
          this.$set(this.uploadContractModal.file, 'guid', newFileId);

          this.$notify({
            header: 'Файл успешно загружен',
            type: 'success',
            timer: 5000,
          });

          this.$set(this.uploadContractModal, 'loading', false);
        });
      }
    },

    onRemoveFile() {
      const { file } = this.uploadContractModal;

      if (file.guid) {
        this.removeFiles({ filesToRemove: [file.guid] });
      }

      this.$set(this.uploadContractModal.file, 'name', '');
      this.$set(this.uploadContractModal.file, 'guid', null);
    },

    onUploadFile() {
      this.uploadFile({
        fileGuid: this.uploadContractModal.file.guid,
        fileName: this.uploadContractModal.file.name,
        customerId: this.form.customerId,
        contractNumber: this.uploadContractModal.number,
      });

      this.$set(this.uploadContractModal.file, 'name', '');
      this.$set(this.uploadContractModal.file, 'guid', null);
      this.$set(
        this.uploadContractModal,
        'open',
        !this.uploadContractModal.open
      );
    },

    onToggleExpandButton(row, index) {
      if (this.expandedRow?.number === row.number) {
        this.expandedRow = null;
        return;
      }
      this.expandedRow = { ...row, rowIndex: index };
    },

    getSortedList({ list, fieldType, fieldName, orderType }) {
      const listCopy = [...list];

      if (fieldType === 'date') {
        if (orderType === 'desc') {
          return listCopy.sort((a, b) => {
            const momentA = this.$moment.parseZone(a[fieldName], 'DD.MM.YYYY');
            const momentB = this.$moment.parseZone(b[fieldName], 'DD.MM.YYYY');

            if (momentA.isBefore(momentB)) return 1;
            if (momentA.isAfter(momentB)) return -1;
            return 0;
          });
        }

        return listCopy.sort((a, b) => {
          const momentA = this.$moment.parseZone(a[fieldName], 'DD.MM.YYYY');
          const momentB = this.$moment.parseZone(b[fieldName], 'DD.MM.YYYY');

          if (momentA.isBefore(momentB)) return -1;
          if (momentA.isAfter(momentB)) return 1;
          return 0;
        });
      }

      if (fieldType === 'number') {
        if (orderType === 'desc') {
          return listCopy.sort((a, b) => {
            const numA = parseInt(a[fieldName]);
            const numB = parseInt(b[fieldName]);
            return numA - numB;
          });
        }

        return listCopy.sort((a, b) => {
          const numA = parseInt(a[fieldName]);
          const numB = parseInt(b[fieldName]);
          return numB - numA;
        });
      }

      if (orderType === 'desc') {
        return listCopy.sort((a, b) => {
          if (a[fieldName] < b[fieldName]) return 1;
          if (a[fieldName] > b[fieldName]) return -1;
          return 0;
        });
      }

      return listCopy.sort((a, b) => {
        if (a[fieldName] > b[fieldName]) return 1;
        if (a[fieldName] < b[fieldName]) return -1;
        return 0;
      });
    },
  },
};
</script>

<style lang="scss">
.contracts-table__services-table {
  display: block;
}

.services-table {
  padding: 10px;
  background-color: var(--blue);
  color: var(--white);
}

.services-table__row {
  display: grid;
  grid-template-columns: 50% 20% 10% 20%;
  gap: 10px;
}

.services-table .row {
  border-bottom: 1px solid var(--white);

  &:last-child {
    border-bottom: none;
  }
}

.services-table .row .data {
  padding: 10px 5px;
  border-right: 1px solid var(--white);
  text-align: start;

  &:last-child {
    border: none;
  }
}

.contracts-table-tab {
  & .contracts-table-tab__file-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  & .contracts-table-tab .file-buttons {
    & .button {
      width: 140px;
    }
  }

  & .upload-file-modal__input {
    margin-bottom: 30px;
  }

  & .upload-file-modal__controls {
    display: flex;
    gap: 10px;
  }

  & .controls__control {
    flex-grow: 1;
  }
}
</style>
